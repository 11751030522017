import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { nrPageAction } from './util-helpers/newrelic';
import { AddInStorage } from './storage/AddInStorage';
import Wrapper from './Wrapper';

let LSAccess;

try {
  // test for LS access
  AddInStorage.getItem('userTenant');
  LSAccess = true;
} catch (error) {
  LSAccess = false;
  nrPageAction('outlookLoad', { message: `LS Access: ${error}` });
}

if (LSAccess) {
  // must conditionally require the loader or the app crashes on LS access
  // eslint-disable-next-line
  const loadApp = require('./app-loader');

  // First render the Wrapper
  const container = document.getElementById('container');
  if (container) {
    ReactDOM.render(
      <Wrapper>
        <div id="app-content"></div>
      </Wrapper>,
      container
    );

    // Then let the app loader render into app-content
    loadApp.default();
  }
} else {
  document.write(' There was an error with cookies or AddInStorage permissions. \n Please ensure they are enabled for this site and 3rd party-cookies.');
}
