import React, { FC, useEffect, useState } from 'react';
import LinearProgress from '@fv-components/linear-progress';
import FVID from '../FVID';
import { AddInStorage } from '../../storage/AddInStorage';

const css = require('./Login.module.scss');

const Callback: FC = () => {
  const [name, setName] = useState<string | undefined | null>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    FVID.exchangeCodeForToken(code as string)
      .then(async (data) => {
        try {
          const user = await FVID.getUserInfo(data.access_token);
          setName(user.name);
          AddInStorage.setItem('user', user);
          AddInStorage.setItem('access_token', data.access_token);
          AddInStorage.setItem('refresh_token', data.refresh_token);

          Office.context.ui.messageParent(JSON.stringify({ status: 'success', data: AddInStorage.store }));
        } catch (error) {
          console.error('Failed to get user info:', error);
          Office.context.ui.messageParent(JSON.stringify({ status: 'error', data: error }));
        }
      })
      .catch((error) => {
        console.error('Token exchange failed:', error);
        Office.context.ui.messageParent(JSON.stringify({ status: 'error', data: `Authentication failed` }));
      });
  }, []);

  return (
    <div className={css.container}>
      <div className={css.content} style={{ paddingTop: 50 }}>
        <div className={css.fvidLoginBox} style={{ height: 250 }}>
          <img
            alt="Filevine"
            height="45"
            src="assets/fv-logo.svg"
            style={{ marginLeft: 50, marginBottom: 30 }}
          />
          {!name && (
            <div style={{ padding: 30 }}>
              <LinearProgress />
              <br />
              Logging you in...
            </div>
          )}
          {name && (
            <>
              <p>
                You are signed in as
                <b>
                  &nbsp;
                  {name}
                </b>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Callback;