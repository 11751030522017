interface DialogOptions {
  url: string;
  height?: number;
  width?: number;
  onMessage?: (message: string) => void;
  onError?: (error: any) => void;
}

let dialog: Office.Dialog | null = null;

const getDialogDimensions = (desiredWidth: number = 500, desiredHeight: number = 700) => {
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  const widthPercent = Math.min(100, Math.round((desiredWidth / screenWidth) * 100));
  const heightPercent = Math.min(100, Math.round((desiredHeight / screenHeight) * 100));

  return { width: widthPercent, height: heightPercent };
};

const openDialog = (options: DialogOptions) => {
  const {
    url,
    onMessage = () => { },
    onError = () => { },
  } = options;

  if (options.url === '') {
    return;
  }

  const dimensions = getDialogDimensions();
  Office.context.ui.displayDialogAsync(
    url,
    {
      ...dimensions,
      promptBeforeOpen: false,
      displayInIframe: false
    },
    (result: Office.AsyncResult<Office.Dialog>) => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        console.error('Failed to open dialog:', result.error);
        onError(result.error);
        return;
      }

      dialog = result.value;
      dialog.addEventHandler(Office.EventType.DialogMessageReceived,
        (arg: { message: string | boolean; } | { error: number; }) => {
          if ('message' in arg) {
            const message = JSON.parse(arg.message as any);
            if (message.status === "success") {
              onMessage(message.data);
            } else {
              onError(message.data);
            }
            closeDialog();
          } else if ('error' in arg) {
            console.error(`Dialog message received with error ${arg.error}`);
          }
        });

      dialog.addEventHandler(Office.EventType.DialogEventReceived,
        () => {
          // ignore these events
        });
    }
  );
};

const closeDialog = () => {
  if (!dialog) return;

  try {
    dialog.close();
  } catch (error) {
    console.log('Dialog already closed');
  } finally {
    dialog = null;
  }
};

export { openDialog, closeDialog };
