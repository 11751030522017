import React, {
  useState, FC, useEffect, useRef, useCallback,
} from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';
import Button from '@fv-components/button';

// FV API
import useQueryAttachedProjects from '../util-api/useQueryAttachedProjects';
import { IProject, IProjectSearchPayload } from '../util-api/models/project';
import {
  logout,
  getOrgId,
  setOrgId,
  getCurrentOrg,
} from '../Auth/auth';

// Utilities
import useApolloClient from '../util-api/apolloClient';
import {
  getAsyncAppointmentAttendees,
  getCurrentEmailId,
  getIsMeeting,
  getIsMeetingClass,
} from '../util-helpers/office';
import { logoutMSALPopup } from '../util-helpers/msal';

// Child Components
import ErrorMsg from '../ErrorMsg';
import EphemeralId from '../EphemeralId';
import ProjectContext, { ICurrentProject, initialProjectSearchState } from './ProjectContext';
import ProjectWorkspace from './ProjectWorkspace';
import ProjectSearchBar from './ProjectSearchBar';
import ProjectSearchResults from './ProjectSearchResults';

// Styles
import '../App.scss';

import FilevineIcon from './FilevineIcon';
import GenericCard from './ProjectWorkspace/GenericCard';
import CreateProjectForm from './CreateProject';
import SearchLimitWarning from './SearchLimitWarning';
import { safeRedirect } from '../util-helpers/common';

const css = require('./Taskpane.module.scss');

interface ITaskPaneProps {
  currentEmailId?: string;
  initialProject?: ICurrentProject;
}

const Taskpane: FC<ITaskPaneProps> = ({
  currentEmailId,
  initialProject,
}: ITaskPaneProps) => {
  const [currentProject, setCurrentProject] = useState(initialProject);
  const [emailId, setEmailId] = useState(currentEmailId);
  const [shouldClearCurrentProject, setShouldClearCurrentProject] = useState<boolean>(false);
  const [projectSearchResults, setProjectSearchResults] = useState<IProjectSearchPayload>(
    initialProjectSearchState,
  );

  const { client } = useApolloClient();

  const { getAttachedProjects, attachedProjectsResults } = useQueryAttachedProjects(client!);

  const prevEmailId = useRef<string>();

  const isAppointment = getIsMeeting();
  const isMeetingReq = getIsMeetingClass();

  const clearCurrentProject = useCallback(() => {
    if (currentProject) {
      setShouldClearCurrentProject(true);
      setCurrentProject(undefined);
    }
  }, [currentProject]);

  const [errorMsg, setErrorMsg] = useState('');
  const handleSignOutMSAL = () => {
    setErrorMsg('');
    try {
      logoutMSALPopup(() => {
        safeRedirect('/');
      }, (error: string) => setErrorMsg(error));
    } catch (error) {
      setErrorMsg(`${error}`);
    }
  };

  useEffect(() => {
    const item = Office?.context?.mailbox?.item;
    if (item) {
      if (!shouldClearCurrentProject
        && isAppointment
        && projectSearchResults?.items.length
      ) {
        const asyncFunc = async () => {
          const recentProjects = projectSearchResults?.items;
          const rpEmails = recentProjects.map((rp: IProject) => rp.projectEmailAddress);

          // When a project email is added via the add-in it goes on optional attendees
          const optAttendees = await getAsyncAppointmentAttendees(item.optionalAttendees);
          const email = rpEmails.length
            && optAttendees
            && rpEmails.find((addr: string) => optAttendees.find((oa: string) => addr === oa));
          if (email) {
            const project = recentProjects.find((rp: IProject) => rp.projectEmailAddress === email);
            if (project) {
              setCurrentProject(
                {
                  data: project,
                  id: project.projectId.native,
                },
              );
            }
          }
        };
        asyncFunc();
      }

      // Opening an email message that is a calendar invite
      // that has a project email as an attendee/recipient
      if (isMeetingReq) {
        const recentProjects = projectSearchResults?.items;
        const rpEmails = recentProjects.map((rp: IProject) => rp.projectEmailAddress);

        const attendees = [...item.cc, ...item.to];
        const email = attendees
          && rpEmails.find((addr: string) => attendees.find(
            (oa: Office.EmailAddressDetails) => addr === oa.emailAddress,
          ));
        if (email) {
          const project = recentProjects.find((rp: IProject) => rp.projectEmailAddress === email);
          if (project) {
            setCurrentProject(
              {
                data: project,
                id: project.projectId.native,
              },
            );
          }
        }
      }

      if (emailId
        && prevEmailId.current !== emailId
        && !emailId?.includes('NO-EMAIL-ID-YET')
      ) {
        getAttachedProjects(emailId);
      }
      prevEmailId.current = emailId;
    }
  }, [
    getAttachedProjects,
    currentEmailId,
    isAppointment,
    isMeetingReq,
    projectSearchResults,
    setCurrentProject,
    shouldClearCurrentProject,
    emailId,
  ]);

  // re-render if user has add-in pinned and changes messages behind the add-in
  useEffect(() => {
    if (Office.context?.mailbox?.addHandlerAsync !== undefined) {
      Office.context.mailbox.addHandlerAsync(
        Office.EventType.ItemChanged,
        async () => {
          const id = await getCurrentEmailId();
          if (id !== emailId) {
            setEmailId(id);
          }
        },
      );
    }
  }, [emailId]);

  useDeepCompareEffect(() => {
    // If the email is attached to a project we set that as the current project
    if (attachedProjectsResults?.items.length === 1
      && attachedProjectsResults.items[0].projectId.native !== currentProject?.id) {
      setCurrentProject(
        {
          data: attachedProjectsResults.items[0],
          id: attachedProjectsResults.items[0].projectId.native,
        },
      );

      // we also need to update the org too if it changed
      const orgId = getOrgId();
      const projectOrgId = attachedProjectsResults.items[0].orgId;
      if (orgId !== projectOrgId) {
        setOrgId(projectOrgId);
      }
    }
  }, [
    attachedProjectsResults,
    getAttachedProjects,
  ]);

  const [isProjectCreateCard, setIsProjectCreateCard] = useState(false);
  const currentOrg = getCurrentOrg();
  const [searchTerm, setSearchTerm] = useState<string>();

  return client ? (
    <ApolloProvider client={client}>
      <ProjectContext.Provider value={{
        currentEmailId: emailId,
        currentProjectData: currentProject ? currentProject.data : undefined,
        currentProjectId: currentProject ? currentProject.id : undefined,
        projectSearchResults,
        setCurrentProject,
        setProjectSearchResults,
      }}
      >
        <EphemeralId />
        <div
          className={[
            css.taskpaneContainer,
            currentProject ? css.viewportHeight : '',
          ].join(' ')}
        >

          <div className={css.taskpaneHeader}>
            {/* Org Selection and Project Search */}
            <ProjectSearchBar
              clearCurrentProject={clearCurrentProject}
              onProjectSearchTerm={(term?: string) => {
                if (term !== searchTerm) setSearchTerm(term);
              }}
            />

            {/* Create Project */}
            {!currentProject && (
              <GenericCard
                buttonIcon={(
                  <FilevineIcon
                    icon="plus"
                    className={css.icon}
                  />
                )}
                buttonText="Create Project"
                outlined={false}
                child={(
                  <CreateProjectForm
                    orgName={currentOrg?.name || ""}
                    onProjectCreateSuccess={(p: IProject) => (
                      setCurrentProject({ data: p, id: p.projectId.native }))}
                  />
                )}
                onOpen={() => setIsProjectCreateCard(true)}
                onClose={() => isProjectCreateCard && setIsProjectCreateCard(false)}
                genCardButtonClass={css.createProjectCard}
                dataTestUniq="createProjectForm"
              />
            )}

            {/* Project Search Results Count */}
            {!currentProject && (!projectSearchResults.hasMore ? (
              <div className={css.searchBarResultsCount}>
                {searchTerm && projectSearchResults ? `Project search results: ${projectSearchResults.count}` : `Recent projects: ${projectSearchResults.count}`}
              </div>
            ) : (
              <SearchLimitWarning limit={projectSearchResults.limit} />
            ))}
          </div>

          {/* Project Search Results or Project Workspace */}
          {!currentProject ? <ProjectSearchResults /> : <ProjectWorkspace />}

          {/* Sign Out */}
          {(!currentProject && !isProjectCreateCard) && (
            <>
              <Button
                className={css.signOutBtn}
                onClick={() => logout()}>
                Sign Out
              </Button>
              <Button
                className={css.signOutMSALBtn}
                onClick={handleSignOutMSAL}>
                Sign Out of Microsoft
              </Button>
            </>
          )}
          {errorMsg && (
            <ErrorMsg
              errorText={errorMsg}
            />
          )}
        </div>
        <div style={{ display: 'none' }}>
          Version: 20241030.1341
        </div>
      </ProjectContext.Provider>
    </ApolloProvider>
  ) : <></>;
};

export default Taskpane;
