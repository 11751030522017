import React, { FC, useState, useEffect } from 'react';
import Button from '@fv-components/button';

import { getConfig } from '../../config';
import { getIsOutlook } from '../../util-helpers/office';
import { safeRedirect, setAddInStorage } from '../../util-helpers/common';
import EphemeralId from '../../EphemeralId';
import { openDialog } from '../../util-helpers/officeDialog';
import { ENV_SETTNGS, generateHeaders, getEnvironmentSuffix } from '../FVID';
import { version } from '../../version.json';

const css = require('./Landing.module.scss');

const versionStyle: React.CSSProperties = {
  color: '#999',
  fontSize: '10px',
  marginTop: '24px',
  textAlign: 'center',
  width: '100%'
};

const Landing: FC = () => {
  const [isLoginComplete, setIsLoginComplete] = useState(false);

  const { hostUrl } = getConfig();

  const processLoginProperties = (args: any) => {
    setAddInStorage(args);
    generateHeaders(true).then(() => {
      setIsLoginComplete(true);
    });
  };

  useEffect(() => {
    if (isLoginComplete) {
      safeRedirect(hostUrl);
    }
  }, [isLoginComplete, hostUrl]);

  useEffect(() => {
    if (window.location.pathname === "/signout") {
      window.location.assign("/logout");
    }
  }, []);

  const isOutlook = getIsOutlook();

  const defaultMsgTitle = 'Sign in with your Filevine account.';
  const defaultMsgDesc = isOutlook ? 'The Filevine Add-in provides a window into your Filevine projects from inside Outlook. Quickly send emails to your Filevine project, add activities, and reference project data, all without leaving Outlook.'
    : 'The Filevine Add-in provides a window into your Filevine projects from inside Office. Quickly save documents, spreadsheets, or presentations to your Filevine project, add activities, and reference project data, all without leaving Office.';
  const ctaMessage = defaultMsgTitle;
  // eslint-disable-next-line no-nested-ternary
  const ctaDescription = defaultMsgDesc;

  return <>
    <div className={css.loginContainer}>
      <EphemeralId />
      <img src="assets/fv-logo.png" alt="Filevine" />
      <span className={css.loginCTA}>
        {ctaMessage}
      </span>
      <Button
        id="login_button_fvid"
        raised
        type="submit"
        className={css.loginButton}
        data-test="sign-in"
        onClick={() => {
          openDialog({
            url: `${ENV_SETTNGS[getEnvironmentSuffix()].CLIENT_ROOT}/login`,
            onMessage: processLoginProperties
          });
        }}>
        Sign In
      </Button>
      <small>
        <div>
          {ctaDescription}
        </div>
        <br />
        <br />
        <div>
          Don’t have a Filevine account?
        </div>
        <a href="https://www.filevine.com/demo-request?utm_source=outlook" target="_blank" rel="noopener noreferrer">Schedule your demo today.</a>
      </small>
      <div style={versionStyle}>
        {`v${version}`}
      </div>
    </div>
  </>;
};

export default Landing;
