import { ENV_SETTNGS, EnvironmentType, getEnvironmentSuffix } from './Auth/FVID';
import { getIsTestEnv } from './util-helpers/common';
import { AddInStorage } from './storage/AddInStorage';

export interface IConfig {
  filevineApiBaseUrl: string;
  filevineBaseDomain: string;
  filevineBaseUrl: string;
  filevineTenantUrl: string;
  tenant: string;
  hostUrl: string;
  ephemeralId: string;
}

interface IIdentityProviders {
  name: string;
}

interface IAppClient {
  appClientID: string;
  clientType: string;
  clientTypeValue: number;
  identityProviders: IIdentityProviders;
}

export interface ITenantConfig {
  appClientID?: string;
  authorizationEndpoint?: string;
  awsRegion?: string;
  cognitoUserPoolID?: string;
  tenantID?: string;
  identityProviders?: IIdentityProviders[];
  error?: string;
}

export const shardGroups: { [key: string]: string[] } = {
  prod: ['.filevineapp.com', '.filevineapp.ca'],
  preprod: ['.filevinedev.com', '.testonly.com'],
};

export const envShards = shardGroups[process.env.REACT_APP_GROUP || ''];

export const testTenantConfig = {
  appClientID: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
  authorizationEndpoint: '',
  awsRegion: 'ca-42',
  cognitoUserPoolID: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
  tenantID: 'asdf1234',
  identityProviders: [],
};

export const setUserTenant = (userTenant: string): void => {
  AddInStorage.setItem('userTenant', userTenant);
};

export const getUserTenant = (): string => (
  AddInStorage.getItem('userTenant') || ''
);

export const setUserDomain = (userDomain: string): void => {
  localStorage.setItem('userDomain', userDomain);
};

export const getUserDomain = (): string => (
  localStorage.getItem('userDomain') || ''
);

export const getConfig = (): IConfig => {
  const userDomain = getUserDomain();
  const tenant = getIsTestEnv() ? 'fru' : getUserTenant();
  const filevineBaseDomain = getIsTestEnv() ? '.filevinedev.com' : userDomain;
  const filevineTenantUrl = `${tenant}${filevineBaseDomain}`;
  const environment: EnvironmentType = getEnvironmentSuffix();
  const config = {
    filevineApiBaseUrl: ENV_SETTNGS[environment].API_BASE || '',
    filevineBaseDomain,
    filevineBaseUrl: ENV_SETTNGS[environment].API_BASE || '',
    filevineTenantUrl,
    tenant,
    hostUrl: ENV_SETTNGS[environment].CLIENT_ROOT || '',
    ephemeralId: process.env.REACT_APP_EPHEMERAL_ID || '',
  };
  return config;
};

export const fetchTenantConfig = (tenant: string): Promise<ITenantConfig> | ITenantConfig => {
  if (getIsTestEnv()) {
    return testTenantConfig;
  }

  const prodAuthAPIUrl = 'auth-prod-public.filevine.com';
  const prodCanAuthAPIUrl = 'auth-canada-public.filevine.ca';
  // Currently unused but leaving here if we want to add CJIS support later
  // prodCJISAuthAPIUrl = 'auth-cjis-public.filevinegov.com';

  const nonprodAuthAPIUrl = 'auth-nonprod-public.filevinedev.com';
  // const authAPIUrl = 'auth-auth-dev-public.filevinedev.com'; // leave for testing

  let authAPIUrl = prodAuthAPIUrl;
  if (getUserDomain() === '.filevineapp.ca') authAPIUrl = prodCanAuthAPIUrl;

  // Must be last so we cant call prod or ca from preprod envs
  if (process.env.REACT_APP_GROUP === 'preprod') authAPIUrl = nonprodAuthAPIUrl;

  return fetch(`https://${authAPIUrl}/api/Tenants/authconfig/${tenant}`, {})
    .then(((response) => {
      if (response.status === 404) {
        return response.status;
      }
      return response.json();
    }))
    .then((data) => {
      // if api returns 404 return custom error text
      if (data === 404) {
        return {
          error: `A match for ${tenant} was not found.`,
        };
      }

      const {
        appClients, authorizationEndpoint, awsRegion, cognitoUserPoolID, tenantID,
      } = data;

      if (appClients?.length && cognitoUserPoolID) {
        const { appClientID, identityProviders } = appClients.find((c: IAppClient) => c.clientType === 'Filevine');

        return {
          appClientID,
          authorizationEndpoint,
          awsRegion,
          cognitoUserPoolID,
          identityProviders,
          tenantID,
        };
      }

      return data;
    }).catch(() => ({
      error: 'There was an error fetching your tenant details from Filevine. Error: FVOC984',
    }));
};

export const setTenantConfig = (userTenantConfig: ITenantConfig | null): void => {
  AddInStorage.setItem('userTenantConfig', userTenantConfig);
};

export const getTenantConfig = async (): Promise<ITenantConfig> => {
  const imConfig = AddInStorage.getItem('userTenantConfig');
  if (imConfig) {
    return imConfig;
  }

  const fConfig = await fetchTenantConfig(getUserTenant());
  if (fConfig?.cognitoUserPoolID) {
    setTenantConfig(fConfig);
    return fConfig;
  }

  return fConfig;
};
