import dayjs from 'dayjs';

import TurndownService from 'turndown';
import { getConfig } from '../config';
import { AddInStorage } from '../storage/AddInStorage';

const nodeEnvs = {
  PROD: 'production',
  TEST: 'test',
  DEV: 'development',
};

const dateFormat = {
  DATE_AND_TIME: 'MM/DD/YYYY h:mm a',
  DATE_ONLY: 'MM/DD/YYYY',
  DOW_AND_DATE: 'dddd, MMMM DD, YYYY',
  SHORT_MONTH_DAY_YEAR: 'MMM D, YYYY',
};

const getIsDebug = () => new URLSearchParams(window.location.search).get('debug') === 'true';
const getIsProdEnv = () => (process.env.NODE_ENV === nodeEnvs.PROD) && !/filevinedev.com/.test(window.location.href);
const getIsNonProdEnv = () => window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname.includes("filevinedev");
const getIsLocalDevelopment = () => window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
const getIsTestEnv = () => (process.env.NODE_ENV === nodeEnvs.TEST);

const formatDate = (value: string) => dayjs(value).format(dateFormat.SHORT_MONTH_DAY_YEAR);

const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

const getFileExtension = (fileName: string) => fileName.substr(fileName.lastIndexOf('.') + 1);

const configHasError = (): boolean => {
  let hasError = false;
  const config = getConfig();
  Object.entries(config).forEach(([key, value]) => {
    if (value === undefined) {
      // eslint-disable-next-line no-console
      console.log(`Error in Auth Config: ${key}: ${value}`);
      hasError = true;
    }
  });
  return hasError;
};

let attachmentsArray: string[] = [];
const isAttachmentInArray = (attachmentName: string)
  : Boolean => attachmentsArray.includes(attachmentName);

const pushToAttachmentArray = (attachmentName: string) => {
  attachmentsArray.push(attachmentName);
};
const clearAttachmentArray = () => {
  attachmentsArray = [];
};
const simplifyHtml = (text: string) => {
  const service = new TurndownService();
  service.addRule('div to linebreak', {
    filter: 'div',
    replacement: (content: any) => `${content}<br />`,
  })
    .addRule('a to link', {
      filter: 'a',
      replacement: (
        content: string,
        node: TurndownService.Node,
      ) => `[${content}](${(node as HTMLElement).getAttribute('href')})`,
    });

  const simplifedHtml = `<html><body>${service.turndown(text)}</body></html>`;

  return simplifedHtml;
};

// NOTE: Safari web browser currently has an issue with latest auth api
// this is to disable auth on Safari while the issue is addressed
// EdgiOS: the UA for Edge browser on iOS
// CriOs: the UA for Chrome browser on iOS
const isSafariWebBrowser = (
  ua: string,
  vendor: string,
) => ua.toLowerCase().includes('safari')
    && !ua.includes('CriOS')
    && !ua.includes('EdgiOS')
    && vendor.toLowerCase().includes('apple computer');

const isIPadWebBrowser = (ua: string) => ua.toLowerCase().includes('ipad');

const countDecimals = (n: number) => (((n % 1) !== 0) ? n.toString().split('.')[1].length : 0);

const truncateText = (text: string, max: number): string => ((text.length > max) ? `${text.substring(0, max)}...` : text);

const getUpdatedPartnerId = (currentEmailId?: string, currentProjectId?: number) => {
  if (currentEmailId && currentProjectId) {
    return `outlookId:${currentEmailId}-projectId:${currentProjectId}`;
  }
  return `outlookId:${Date.now()}-projectId:developerMode`;
};

const setAddInStorage = (obj: any) => {
  if (obj === null || obj === undefined) return;
  Object.entries(obj).forEach(([k, v]) => {
    AddInStorage.setItem(k, v);
  });
};

const setLocalStorage = (obj: any) => {
  if (obj === null || obj === undefined) return;
  Object.entries(obj).forEach(([k, v]) => {
    localStorage.setItem(k, typeof v === 'string' ? v : JSON.stringify(v));
  });
};

const isInsidePopup = () => {
  if (window.opener) {
    return true;
  }
    return false;
};

const isNullOrUndefined = (obj: any): boolean => {
  return obj === null || obj === undefined;
};

const safeRedirect = (url: string) => {
  AddInStorage.persistData();
  const [urlWithoutParams, queryString] = url.split('?');
  const urlParams = new URLSearchParams(queryString || '');
  urlParams.set('sk', AddInStorage.getStorageKey());
  window.setTimeout(() => {
    window.location.assign(`${urlWithoutParams}?${urlParams.toString()}`);
  }, 500);
};

enum LocalStorageKey
{
  AccessToken = 'accessToken',
  OrgId = 'orgId',
  UserId = 'userId',
  UserOrgs = 'userOrgs',
  SAMLAuthProvider = 'SAMLAuthProvider',
  User = 'user',
  UseFVID = 'useFVID'
}

export {
  nodeEnvs,
  getIsDebug,
  getIsProdEnv,
  getIsTestEnv,
  getIsNonProdEnv,
  getIsLocalDevelopment,
  dateFormat,
  simplifyHtml,
  configHasError,
  formatBytes,
  formatDate,
  getFileExtension,
  pushToAttachmentArray,
  clearAttachmentArray,
  isAttachmentInArray,
  isSafariWebBrowser,
  isIPadWebBrowser,
  isNullOrUndefined,
  countDecimals,
  truncateText,
  getUpdatedPartnerId,
  setLocalStorage,
  isInsidePopup,
  LocalStorageKey,
  setAddInStorage,
  safeRedirect
};
