import React, { FC, useEffect, useState } from 'react';
import { handleMSALRedirect } from '../util-helpers/msal';
import { nrNoticeError } from '../util-helpers/newrelic';

const containerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100%',
  textAlign: 'center'
};

const MsalAuth: FC = () => {
  const [isOfficeReady, setIsOfficeReady] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("Checking for Office.js availability...");
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;
  const RETRY_INTERVAL = 2000; // 2 seconds

  const checkOfficeAvailability = () => {
    if (global.Office || Office) {
      Office.onReady().then(() => {
        setIsOfficeReady(true);
        handleMSALRedirect();
      }).catch((error) => {
        setIsOfficeReady(false);
        console.error('Error initializing Office.js:', error);
        nrNoticeError(error, { component: 'MsalAuth' });
        setFeedbackMessage('Error initializing Office.js');
      });
      return true;
    }
    return false;
  };

  useEffect(() => {
    const attemptConnection = () => {
      if (retryCount >= MAX_RETRIES) {
        setFeedbackMessage('Office.js is not available after maximum retry attempts');
        nrNoticeError(new Error('Office.js is not available after maximum retry attempts'), { component: 'MsalAuth' });
        return;
      }

      if (!checkOfficeAvailability()) {
        setFeedbackMessage(`Checking for Office.js availability... (Attempt ${retryCount + 1}/${MAX_RETRIES})`);
        setTimeout(() => {
          setRetryCount(retryCount + 1);
        }, RETRY_INTERVAL);
      }
    };

    attemptConnection();
  }, [retryCount]);

  return (
    <div style={containerStyle}>
      {!isOfficeReady ? feedbackMessage : 'Loading...'}
    </div>
  );
};

export default MsalAuth;
