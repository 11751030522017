import React from 'react';
import EphemeralId from '../../EphemeralId';
import { AddInStorage } from '../../storage/AddInStorage';

function Success() {
  if (Office?.context?.host) {
    Office.context.ui.messageParent(JSON.stringify({ AddInStorage: AddInStorage.store }));
  }

  return (
    <>
      <EphemeralId />
      <div>Success</div>
      <div>If this window does not close please close it and refresh the main add-in.</div>
    </>
  );
}

export default Success;
