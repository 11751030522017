import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import msalPublicClientAppInstance from './msalClient';
import { nrPageAction } from './newrelic';
import { getConfig } from '../config';
import { getOrgId } from '../Auth/auth';
import { safeRedirect, setLocalStorage } from './common';
import { AddInStorage } from '../storage/AddInStorage';

const { hostUrl, tenant } = getConfig();
const orgId = getOrgId() || '';

const logMSApiEvent = (
  message: string,
  eventType: 'MSALCall' | 'MSALDialog' | 'MSGraphCall',
): void => {
  nrPageAction(eventType, { message, orgId, tenant });
};

const scopes = ['User.Read', 'Mail.ReadWrite', 'Mail.Send', 'Mail.Read'];

const fetchMSALAccount = async (): Promise<AccountInfo | undefined> => {
  const MSALAccount = msalPublicClientAppInstance.getActiveAccount();
  if (MSALAccount) {
    return MSALAccount;
  }

  try {
    logMSApiEvent('Attempt', 'MSALCall');
    const userName = Office?.context?.mailbox?.userProfile?.emailAddress;
    if (!userName) {
      logMSApiEvent('No userName found in Office context', 'MSALCall');
      return undefined;
    }

    const accounts = await msalPublicClientAppInstance.getAllAccounts();
    if (!accounts.length) {
      logMSApiEvent('No active accounts found', 'MSALCall');
      return undefined;
    }

    const accountMatch = accounts.find((a) => a.username === userName);
    if (accountMatch) {
      logMSApiEvent('Success, account match found', 'MSALCall');
      msalPublicClientAppInstance.setActiveAccount(accountMatch);
      return accountMatch;
    }
    // We may need to present an error to the user if we see this a lot
    logMSApiEvent(`No matching userName in ${accounts.length} accounts`, 'MSALCall');
    return undefined;
  } catch (error) {
    logMSApiEvent(`${error}`, 'MSALCall');
    return undefined;
  }
};

const fetchMSALResult = async (): Promise<AuthenticationResult | undefined> => {
  const account = await fetchMSALAccount();

  if (account) {
    return msalPublicClientAppInstance.acquireTokenSilent({
      account,
      scopes,
    });
  }

  return undefined;
};

const refreshMSALToken = async (redirectOnFail = false): Promise<boolean> => {
  try {
    const result = await fetchMSALResult();
    if (result?.accessToken) {
      logMSApiEvent('Refresh Token Success', 'MSALCall');
      return true;
    }

    logMSApiEvent('Error, No refresh accessToken', 'MSALCall');
    if (redirectOnFail) {
      safeRedirect('/msalrefresh');
    }
    return false;
  } catch (error) {
    logMSApiEvent(`Token ${error}`, 'MSALCall');
    if (redirectOnFail) {
      safeRedirect('/msalrefresh');
    }
    return false;
  }
};

export enum MSALResponse
{
  Success = 'success',
  Error = 'error',
}

const logoutMSALPopup = (onDone: VoidFunction, onError: (e: string) => void) => {
  logMSApiEvent('Logout Opened', 'MSALDialog');
  let dialog: Office.Dialog;
  const dialogCallback = (asyncResult: Office.AsyncResult<Office.Dialog>) => {
    dialog = asyncResult.value;
    dialog.addEventHandler(
      Office.EventType.DialogMessageReceived, (args) => {
        const message = (args as {message: string})?.message;
        if (message) {
          const messageFromDialog = JSON.parse(message);
          if (messageFromDialog.status === MSALResponse.Success) {
            logMSApiEvent('Closed Logout: success', 'MSALDialog');
            onDone();
            dialog.close();
            return;
          }

          if (messageFromDialog.status === MSALResponse.Error) {
            logMSApiEvent(`Redirect Logout: ${messageFromDialog.msg}`, 'MSALDialog');
            onError(messageFromDialog.msg);
            dialog.close();
          }
        }
      },
    );
  };

  if (Office?.context?.ui?.displayDialogAsync) {
    Office.context.ui.displayDialogAsync(
      `${hostUrl}/msallogout`,
      {
        height: 80,
        width: 50,
        promptBeforeOpen: false,
      },
      dialogCallback,
    );
  }
};

const handleMSALLogout = async () => {
  try {
    logMSApiEvent('Logout Redirect', 'MSALDialog');
    await msalPublicClientAppInstance.logoutRedirect();
  } catch (error) {
    Office.context.ui.messageParent(
      JSON.stringify({ status: MSALResponse.Error, msg: `${error}` }),
    );
  }
};

const handleMSALLogoutSuccess = () => {
  if (Office?.context?.host) {
    Office.context.ui.messageParent(
      JSON.stringify({ status: MSALResponse.Success }),
    );
  }
};

const loginMSALPopup = (onSuccess: (state?: string) => void,
  onError: (e: string, state?: string) => void) => {
  let dialog: Office.Dialog;
  const dialogCallback = (asyncResult: Office.AsyncResult<Office.Dialog>) => {
    dialog = asyncResult.value;
    dialog.addEventHandler(
      Office.EventType.DialogMessageReceived, (args) => {
        const message = (args as {message: string})?.message;
        if (message) {
          const messageFromDialog = JSON.parse(message);
          if (messageFromDialog.status === MSALResponse.Success) {
            logMSApiEvent('Closed: success', 'MSALDialog');
            onSuccess(messageFromDialog.state);
            const obj = JSON.parse(message).msalData;
            setLocalStorage(obj);
            dialog.close();
            return;
          }

          if (messageFromDialog.status === MSALResponse.Error) {
            logMSApiEvent(`Closed: ${messageFromDialog.msg}`, 'MSALDialog');
            onError(messageFromDialog.msg);
            dialog.close();
            return;
          }
        }

        // Catch all
        logMSApiEvent('Closed: unknown', 'MSALDialog');
        onError('Closed with no response from dialog');
        dialog.close();
      },
    );
  };

  if (Office?.context?.ui?.displayDialogAsync) {
    logMSApiEvent('Opened', 'MSALDialog');

    Office.context.ui.displayDialogAsync(
      `${hostUrl}/msalauth`,
      {
        height: 80,
        width: 50,
        promptBeforeOpen: false,
      },
      dialogCallback,
    );
  }
};

const handleMSALRedirect = async (): Promise<void> => {
  try {
    const response = await msalPublicClientAppInstance.handleRedirectPromise();
    if (response?.account) {
      msalPublicClientAppInstance.setActiveAccount(response.account);
      if (Office?.context?.host) {
        logMSApiEvent('Account from Response', 'MSALDialog');
        Office.context.ui.messageParent(
          JSON.stringify({
            status: MSALResponse.Success,
            msalData: localStorage,
            state: response?.state
          }),
        );
        return;
      }
    }

    const accounts = msalPublicClientAppInstance.getAllAccounts();
    if (accounts?.length === 0) {
      // No accounts signed-in, attempt to sign a user in
      logMSApiEvent('Redirect', 'MSALDialog');
      msalPublicClientAppInstance.loginRedirect({
        prompt: 'select_account',
        loginHint: Office?.context?.mailbox?.userProfile?.emailAddress,
        scopes,
        state: AddInStorage.getStorageKey()
      });
      return;
    }

    if (accounts?.length > 1) {
      logMSApiEvent('Multiple accounts', 'MSALDialog');
      if (Office?.context?.host) {
        // We may need to add an account picker if this is common. I believe this is an edge case.
        Office.context.ui.messageParent(
          JSON.stringify({ status: MSALResponse.Error, msg: 'More than one account found please log out of one.' }),
        );
      }
      return;
    }

    if (accounts?.length === 1) {
      // ensure the account has an active token
      const token = await msalPublicClientAppInstance.acquireTokenSilent({
        account: accounts[0],
        scopes,
      });
      if (!token?.accessToken) {
        // active account has expired token
        logMSApiEvent('Redirect: Account with expired token', 'MSALDialog');
        msalPublicClientAppInstance.loginRedirect({
          prompt: 'select_account',
          loginHint: Office?.context?.mailbox?.userProfile?.emailAddress,
          scopes,
          state: AddInStorage.getStorageKey()
        });
        return;
      }

      if (Office?.context?.host) {
        logMSApiEvent('Account from Auth', 'MSALDialog');
        Office.context.ui.messageParent(
          JSON.stringify({
            status: MSALResponse.Success,
            msalData: localStorage,
            state: response?.state
          }),
        );
      }
      return;
    }

    // Catch all
    if (Office?.context?.host) {
      logMSApiEvent('Closing, no action', 'MSALDialog');
      Office.context.ui.messageParent(
        JSON.stringify({ status: MSALResponse.Error, msg: 'Unknown Microsoft Authorization Error.' }),
      );
    }
    return;
  } catch (error) {
    logMSApiEvent(`${error}`, 'MSALDialog');
    if (Office?.context?.host) {
      Office.context.ui.messageParent(
        JSON.stringify({ status: MSALResponse.Error, msg: `${error}` }),
      );
    }
  }
};

export {
  fetchMSALAccount,
  fetchMSALResult,
  refreshMSALToken,
  loginMSALPopup,
  logoutMSALPopup,
  handleMSALRedirect,
  handleMSALLogout,
  handleMSALLogoutSuccess,
  logMSApiEvent,
  scopes,
};
