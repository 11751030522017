/* eslint-disable import/prefer-default-export */

export class AddInStorage {
  private static storageKey = `key_${new Date().getTime().toString()}`;

  // Private static store to hold key-value pairs
  public static store: { [key: string]: any } = {};

  // Returns the storage key
  public static getStorageKey(): string {
    return AddInStorage.storageKey;
  }

  // Persists the data to localStorage
  public static persistData(): void {
    localStorage.setItem(AddInStorage.storageKey, JSON.stringify(AddInStorage.store));
    localStorage.setItem('last_storage_key', AddInStorage.storageKey);
  }

  // Restores the data from the last key
  public static restoreDataFromLastKey(): void {
    const lastStorageKey = localStorage.getItem('last_storage_key');
    if (lastStorageKey) {
      AddInStorage.restoreData(lastStorageKey);
    }
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key?.startsWith('key_') && key !== lastStorageKey) {
        localStorage.removeItem(key);
      }
    }
  }

  // Restores the data from localStorage
  public static restoreData(key: string): void {
    const storedData = localStorage.getItem(key);
    if (storedData) {
      AddInStorage.store = JSON.parse(storedData);
    }
  }

  // Returns the value by key or null if not found
  public static getItem(key: string): any | null {
    return AddInStorage.store?.[key] ?? null;
  }

  // Sets the value for the given key
  public static setItem(key: string, value: any): void {
    AddInStorage.store[key] = value;
  }

  // Removes the key from store
  public static removeItem(key: string): void {
    delete AddInStorage.store[key];
  }

  // Clears all the keys
  public static clear(): void {
    AddInStorage.store = {};
    localStorage.clear();
  }
}
