import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { RestLink } from 'apollo-link-rest';
import { useState, useEffect, useCallback } from 'react';
import {
  getAccessToken,
  getOrgId,
  getUserId,
  logout,
} from '../Auth/auth';
import { getConfig } from '../config';
import useIpAddress from '../util-hooks/useIpAddress';
import { nrPageAction } from '../util-helpers/newrelic';
import FVID, { generateHeaders } from '../Auth/FVID';
import { isNullOrUndefined, safeRedirect } from '../util-helpers/common';

const PLEASE_AUTHENTICATE_ERROR_MESSAGE = 'please authenticate';

const { filevineApiBaseUrl, hostUrl } = getConfig();

interface IApolloClientHook {
  client?: ApolloClient<NormalizedCacheObject>;
  restLink?: RestLink;
}

const getErrorLink = (logoutFVID: () => void) =>
  onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      // eslint-disable-next-line no-console
      nrPageAction('apolloClient', { message: `[Query error] Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}, Operation: ${operation.operationName}` });
    });
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    const errorMessage = networkError?.message?.toLocaleLowerCase();
    if (errorMessage?.includes('status code 401')) {
      logoutFVID();
    } else if (errorMessage?.includes(PLEASE_AUTHENTICATE_ERROR_MESSAGE)) {
      // If the user is not authenticated we send them back home to go back thru auth flow.
      nrPageAction('apolloClient', { message: `Unauthenticated Operation: ${operation.operationName} Network Error: ${networkError}` });
      safeRedirect(hostUrl);
    } else if (errorMessage?.includes('AccessNotAllowedException')) {
      // Expired token or user is not authorized
      // If the user is not authorized we send them back thru auth flow.
      nrPageAction('apolloClient', { message: `Unauthorized Operation: ${operation.operationName} Network Error: ${networkError}` });
      logout();
    } else {
      nrPageAction('apolloClient', { message: `Operation: ${operation.operationName} Network Error: ${networkError}` });
    }
  }
});

const getRestLink = () => new RestLink({ uri: filevineApiBaseUrl });

const useApolloClient = (): IApolloClientHook => {
  const { clientIpAddress } = useIpAddress();

  const [state, setState] = useState<IApolloClientHook>({
    restLink: getRestLink(),
  });

  const getAuthLink = useCallback(() => setContext(async (_, { headers, ...rest }) => {
    if (isNullOrUndefined(getOrgId()) || isNullOrUndefined(getUserId())) {
      console.warn('OrgId or userId is not set. Retrieving new orgId and userId');
      await generateHeaders(true);
    }

    const getContext = async () => new Promise<ApolloLink>((resolve, reject) => {
      getAccessToken().then((accessToken: string) => resolve({
        ...rest,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'x-fv-clientIp': clientIpAddress,
          'x-fv-orgId': getOrgId(),
          'x-fv-userId': getUserId(),
          'x-fv-application': 'outlook-addin',
        },
      })).catch(() => reject(new Error('Unable to get access token')));
    });
    const context = await getContext();
    return context;
  }), [clientIpAddress]);

  const getClient = useCallback(() => new ApolloClient({
    cache: new InMemoryCache(),
    // this array needs to be in this specific order for the reverseCommentsLink to be called
    link: ApolloLink.from([getErrorLink(FVID.logout), getAuthLink(), getRestLink()]),
  }), [getAuthLink]);

  useEffect(() => {
    setState((previousState) => ({
      ...previousState,
      client: getClient(),
    }));
  }, [getClient]);

  return state;
};

export default useApolloClient;
