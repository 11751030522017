import React, { FC, useState } from 'react';
import Button from '@fv-components/button';

import { loginMSALPopup, logoutMSALPopup } from '../util-helpers/msal';
import { getConfig } from '../config';

import ErrorMsg from '../ErrorMsg';
import { getIsOutlook } from '../util-helpers/office';
import { safeRedirect } from '../util-helpers/common';

const css = require('./MsalLanding.module.scss');

const { hostUrl } = getConfig();

const MsalLanding: FC = () => {
  const isRefresh = (window.location.pathname === '/msalrefresh');
  const isOutlook = getIsOutlook();

  const [loginError, setLoginError] = useState('');

  const onSuccess = () => {
    safeRedirect(hostUrl);
  };

  const onError = (error: string) => {
    setLoginError(error);
  };

  const handleLoginMSALPopup = () => {
    setLoginError('');
    try {
      loginMSALPopup(onSuccess, onError);
    } catch (error) {
      setLoginError(`${error}`);
    }
  };

  const handleSignOutMSAL = () => {
    setLoginError('');
    try {
      logoutMSALPopup(() => {}, onError);
    } catch (error) {
      setLoginError(`${error}`);
    }
  };

  return (
    <>
      <div className={css.loginContainer}>
        <span className={css.message}>
          Filevine’s Office Add-in allows users to
          {' '}
          {isOutlook ? 'send emails, attach emails to a project, and attach Filevine documents to outgoing emails'
            : 'save documents, spreadsheets, or presentations directly to Filevine projects'}
          .
        </span>
        <span className={css.message}>
          To do this, we need approval to access your Microsoft account.
        </span>
        {isRefresh && (
          <span>
            Microsoft Azure AD Session Expired
          </span>
        )}
        <Button
          raised
          type="submit"
          className={css.loginButton}
          data-test="sign-in"
          onClick={handleLoginMSALPopup}
        >
          {
             isRefresh ? 'Refresh Access' : 'Authorize Access'
          }
        </Button>
        <Button
          type="submit"
          className={css.loginButton}
          data-test="logout-msla"
          onClick={handleSignOutMSAL}
        >
          Sign Out of Microsoft Accounts
        </Button>
        {loginError && (
          <ErrorMsg
            errorText={loginError}
          />
        )}
        <Button
          type="submit"
          className={css.loginButton}
          data-test="go-back"
          onClick={() => safeRedirect(hostUrl)}
        >
          Go back
        </Button>
      </div>
    </>
  );
};

export default MsalLanding;
